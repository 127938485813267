/* @charset "utf-8"; */
/* CSS Document */

// IMPORT - START

@import form-initialization-general
@import form-setup

// IMPORT - END


// GENERAL - START

form
	width: 100%
	div.DivForm
		padding: $SPACE_MEDIUM
		fieldset
			.divMatFormFieldPeriod
				width: 100%
			border: none
			padding: 0px
			margin-bottom: $SPACE_LITTLE
			margin-top: $SPACE_LITTLE
			div.DivInputHorizontalContainer
				margin: 0px
				padding: 0px
				+setBorderRadius(0px)
				display: flex
				justify-content: space-between
		fieldset:last-of-type
			margin-bottom: 0px
	ul.UlListAsignee
		display: flex
		flex-flow: row wrap
		align-content: flex-start
		justify-content: space-between
		li
			box-sizing: border-box
			margin: 0
			width: 250px
			height: auto

div.DivForm
	div.DivPercentageProgress
		position: relative
		height: 40px
		width: 100%
		+setBoxSizing(border-box)
		+setBorderRadius($RADIUS_LAYOUT_FORM)
		div#divSubPercentageProgress
			position: absolute
			height: 40px
			width: 30%
			overflow: hidden
			+setBoxSizing(border-box)
			+setBorderRadius($RADIUS_LAYOUT_FORM)
		span
			position: relative
			color: white
			margin-left: 44%
			padding-top: $SPACE_LITTLE
			+setBoxSizing(border-box)
			+setBorderRadius($RADIUS_LAYOUT_FORM)

div.DivForm
	div.DivCard, div.DivCardFinish
		width: 100%
		height: 100%
		margin-bottom: $SPACE_LITTLE
		margin-top: 5px
		div.DivContainerIcon
			position: relative
			display: flex
			flex-direction: row
			justify-content: flex-end
			padding: 0px
			div.DivBorderImage
				display: flex
				justify-content: center
				align-items: center
				width: 32px
				height: 32px
				margin-right: $SPACE_LITTLE
				background-color: white
				+setBorderRadius(50%)
				img#imageQoute
					width: 26px
					height: 24px
					object-fit: cover
				img#imageApprove
					width: 26px
					height: 24px
					object-fit: cover

div.DivCard, div.DivCardFinish
	margin-top: $SPACE_SMALL
	margin-bottom: $SPACE_SMALL
	padding-left: $SPACE_MEDIUM
	padding-right: $SPACE_MEDIUM
	padding-bottom: $SPACE_MEDIUM
	padding-top: $SPACE_MEDIUM
	+setBoxSizing(border-box)
	+setBorderRadius($RADIUS_LAYOUT_FORM)

div.DivContainerIcon
	padding-left: $SPACE_LITTLE
	padding-right: $SPACE_LITTLE
	padding-bottom: $SPACE_LITTLE
	padding-top: $SPACE_LITTLE
	+setBoxSizing(border-box)
	+setBorderRadius($RADIUS_LAYOUT_FORM)

div.DivBodyGeneral, div.DivVerifierGeneral, div.DivCenterGeneral
	margin-top: $SPACE_HEADERGENERAL_TOP
	padding-bottom: 90px

div.DivVerifierGeneral, div.DivCenterGeneral
	position: relative
	margin: $SPACE_ENORMOUS
	img#imageLogoHeader
		position: inherit !important
		top: 0px
		right: 0px
	div#divMainDashboard
		div.DivContainer
			margin-top: $SPACE_HUGE

div.DivMainGeneral, div.DivMainAsideSearch
	margin-top: $SPACE_MEDIUM
	width: 100%
	height: 100%

div.DivMainAsideSearch
	display: flex
	flex-wrap: nowrap
	justify-content: space-between
	align-content: stretch
	flex-basis: $WIDTH_LAYOUT_SIGNIN
	// align-items: baseline

div.DivContainerHorizontal, div.DivContainerVertical
	width: 100%
	height: 100%
	margin-top: $SPACE_MEDIUM
	display: flex
	flex-wrap: nowrap
div.DivContainerHorizontal
	flex-direction: row
	div.DivContainer
		margin-left: $SPACE_SMALL
		margin-right: $SPACE_SMALL
		display: flex
		flex-direction: column
	div.DivContainer:first-of-type
		margin-left: 0px
	div.DivContainer:last-of-type
		margin-right: 0px
div.DivContainerVertical
	flex-direction: column

div.DivForm
	margin-top: $SPACE_SMALL
	margin-bottom: $SPACE_SMALL
	padding-left: $SPACE_MEDIUM
	padding-right: $SPACE_MEDIUM
	padding-bottom: $SPACE_MEDIUM
	padding-top: $SPACE_MEDIUM
	+setBoxSizing(border-box)
	+setBorderRadius($RADIUS_LAYOUT_FORM)

form div.DivForm fieldset, div.DivForm, div.DivCard, div.DivCardFinish
	div.DivFormHorizontalContainer, div.DivFormVerticalContainer
		display: flex
		justify-content: space-between
		align-content: stretch
		align-items: baseline
		flex-wrap: wrap // nowrap
		div.DivListChart
			margin-top: $SPACE_MEDIUM
			align-self: flex-start
			ul
				height: $HEIGHT_CHART_LIST
				overflow-y: scroll
	div.DivFormHorizontalContainer
		flex-direction: row
		div.DivForm2Column
			margin-left: $SPACE_LITTLE
			margin-right: $SPACE_LITTLE
		div.DivForm2Column:first-of-type
			margin-left: 0px
		div.DivForm2Column:last-of-type
			margin-right: 0px
		div.DivForm2ColumnNoMargin
			margin-left: 0px
			margin-right: 0px
		div.DivForm2ColumnNoMargin:last-of-type:nth-of-type(odd)
			div.DivImageCapture
				width: 50%
	div.DivFormVerticalContainer
		flex-direction: column
		div.DivForm2Column
			margin-top: $SPACE_LITTLE
			margin-bottom: $SPACE_LITTLE
		div.DivForm2Column:first-of-type
			margin-top: 0px
		div.DivForm2Column:last-of-type
			margin-bottom: 0px
	div.DivForm2Column
		flex-basis: 1
		flex-grow: 1
		flex-shrink: 1
		width: 45%

div.DivForm:first-of-type
	margin-top: 0px

div.DivForm:last-of-type
	margin-bottom: 0px

div.DivRoundButtonContainer
	display: flex
	justify-content: flex-end
	input[type=button]
		margin: 0px !important
	label
		margin-top: $SPACE_LITTLE !important

div.DivCenterButtonContainer
	display: flex
	flex-direction: column
	align-items: center

div.DivHorizontal
	display: flex !important
	flex-direction: row !important

div.DivVertical
	display: flex
	flex-direction: column

.MarginTop0
	margin-top: 0px !important

.MarginTop10
	margin-top: 10px !important

.MarginTop30
	margin-top: 30px !important

.MarginBottom5
	margin-bottom: 5px

.MarginBottom10
	margin-bottom: 10px !important

.MarginBottom15
	margin-bottom: 15px !important

.MarginLeft15
	margin-left: 15px

.MarginLeft30
	margin-left: 30px !important

.MarginRight15
	margin-right: 15px

.PaddingLeftRight0
	padding-left: 0px !important
	padding-right: 0px !important

// GENERAL - END


// NAVIGATION - START

nav
	position: relative
	width: $SIZE_LAYOUT_PROFILECONTAINER
	figure
		margin-bottom: $SPACE_BIG
		div, img#imageProfilePicture, span#spanProfileNameInitial
			border-style: solid
			+setBorderRadius(50%)
		div
			position: relative
			margin-left: auto
			margin-right: auto
			margin-bottom: $SPACE_MEDIUM
			width: $SIZE_LAYOUT_PROFILEFRAME
			height: $SIZE_LAYOUT_PROFILEFRAME
			padding: $SPACE_TINY
			border-width: $WIDTH_BORDER_PROFILETHIN
			img#imageProfilePicture, span#spanProfileNameInitial
				width: $SIZE_LAYOUT_PROFILEIMAGE
				height: $SIZE_LAYOUT_PROFILEIMAGE
				border-width: $WIDTH_BORDER_PROFILETHICK
			span#spanProfileNameInitial
				width: $WIDTH_LAYOUT_PROFILENAMEINITIAL
				height: $HEIGHT_LAYOUT_PROFILENAMEINITIAL
				padding-top: $SPACE_SMALL
				padding-bottom: $SPACE_SMALL
	ul.ListNavigation
		li.ListItemNavigation
			ul.ListSubNavigation, ul.ListSubNavigationCalculatePremium
				padding-left: $SPACE_SMALL

// NAVIGATION - END


// SIGN IN - START

div#divBodySignIn
	position: relative
	margin: auto
	width: $WIDTH_LAYOUT_SIGNIN
	height: 100%
	display: flex
	align-items: center
	justify-content: center
	flex-direction: column
	header#headerSignIn
		margin-bottom: $SPACE_BIG
		text-align: center
	form.FormSignInShow
		display: none
	form.FormSignInHide
		display: block

// SIGN IN - END


// HOME - START

div#divBodyHome
	position: absolute
	top: 0px
	left: 0px
	right: 0px
	bottom: 0px
	display: flex
	justify-content: center
	flex-direction: column
	perspective: 1000px
	main
		position: absolute
		padding: $SPACE_ENORMOUS
		top: 0px
		left: 0px
		right: 0px
		bottom: 0px
		+setTransformStyle(preserve-3d)

img#imageLogoHeader
	position: absolute
	width: $WIDTH_LAYOUT_LOGOHEADER
	height: $HEIGHT_LAYOUT_LOGOHEADER
	object-fit: cover

div#divBodyHome
	img#imageLogoHeader
		top: $SPACE_ENORMOUS
		right: $SPACE_ENORMOUS

// HOME - END


// ABOUT - START

img#imageLogoAbout
	display: block
	margin-left: auto
	margin-right: auto
	width: $WIDTH_LAYOUT_LOGOABOUT
	height: $HEIGHT_LAYOUT_LOGOABOUT
	object-fit: cover

// ABOUT - END


// DASHBOARD -  START

.ChartBar
	margin-top: $SPACE_MEDIUM
	margin-bottom: $SPACE_MEDIUM

// DASHBOARD - END


// DYNAMIC CONTAINER - START

div.DivDynamicContainer
	margin-left: $SPACE_SMALL
	width: 100%
	height: 100%
	div.DivTableContainer
		margin-left: 0px !important
	div.DivSubDynamicContainer
		margin-top: $SPACE_MEDIUM
		padding-left: $SPACE_HUGE
		form
			width: 100%
			margin-right: 0px
	div.DivListControl
		display: flex
		justify-content: space-between
		align-items: center
		margin-bottom: $SPACE_MEDIUM
	form:last-of-type
		margin-right: 0px
	form.FormInsertConversation
		margin-bottom: 0px
		div.DivConversationGeneral
			display: flex
			flex-direction: column
			div.DivConversationHeader
				display: flex
				justify-content: space-between
				div.DivConversationInfo
					display: flex
					flex-direction: row
					div.DivConversationInfoDetails
						display: flex
						flex-direction: column
						justify-content: center
			div.DivConversationChatType
				width: 25%
			div.DivConversationContent
				display: flex
				flex-direction: column
				margin-top: $SPACE_LITTLE
				div.DivAttachmentContainer
					width: 100%
					box-sizing: border-box
					display: flex
					align-items: center
					border-bottom-left-radius: $RADUIS_LINK_BUTTONCONVERSATION
					border-bottom-right-radius: $RADUIS_LINK_BUTTONCONVERSATION
					margin-top: 0px
					padding-left: $SPACE_LITTLE
					padding-bottom: $SPACE_LITTLE
					height: $HEIGHT_LAYOUT_BUTTONATTACHMENTCONTAINER
			div.DivConversationProfile
				margin-left: $SPACE_MEDIUM
				+setBorderRadius(50%)

div.DivTransparantContainer
	padding: $SPACE_HUGE
	margin-top: $SPACE_MEDIUM
	+setBorderRadius($RADIUS_LAYOUT_FORM)

// DYNAMIC CONTAINER - END


// EXTRA - START

div.DivFormHorizontalContainer
	div.DivForm2Column
		div.DivImageCapture
			img
				display: block
				width: 95%
				height: 95%
			video
				display: block
				width: 95%
				height: 90vh

// EXTRA - END


// TABLE - START

div.DivTableContainer
	margin-left: $SPACE_SMALL
	width: 100%
	height: 100%
	div.DivTableControl
		display: flex
		justify-content: space-between
		align-items: center
		div.DivTableSubControl
			display: flex
			justify-content: space-between
			align-items: center
	ul.ListTableThumbnails, table, ul.ListTableTicket, ul.ListTableConversation
		margin-top: $SPACE_LARGE
		margin-bottom: $SPACE_LARGE
	ul.ListTableThumbnails, ul.ListTableTicket, ul.ListTableConversation
		display: flex
		list-style-type: none
		+setBorderRadius($RADIUS_LAYOUT_FORM)
		li
			margin: 0px
			padding: $SPACE_LITTLE
	ul.ListTableThumbnails
		justify-content: flex-start
		align-content: stretch
		align-items: baseline
		flex-wrap: wrap
		li
			width: $WIDTH_TABLE_THUMBNAILSFIGURE
			// height: $HEIGHT_TABLE_THUMBNAILSFIGURE
			height: auto
			figure
				position: relative
				margin-left: auto
				margin-right: auto
				text-align: center
				img
					width: $SIZE_TABLE_THUMBNAILSICON
					height: $SIZE_TABLE_THUMBNAILSICON
					margin-bottom: $SPACE_LITTLE
					border: none
				figcaption
					word-wrap: normal
					white-space: normal
					word-break: break-all
	table
		border-collapse: collapse
		width: 100%
		thead, tbody
			tr
				td, th
					padding-top: $SPACE_MEDIUM
					padding-left: $SPACE_LITTLE
					padding-right: $SPACE_LITTLE
					padding-bottom: $SPACE_MEDIUM
					word-wrap: normal
					white-space: normal
					word-break: normal
					figure
						img
							width: $SIZE_TABLE_THUMBNAILSICONSMALL
							height: $SIZE_TABLE_THUMBNAILSICONSMALL
				td:first-of-type, th:first-of-type
					padding-left: $SPACE_MEDIUM
				td:last-of-type, th:last-of-type
					padding-right: $SPACE_MEDIUM
		thead
				th:first-of-type
					+setBorderRadiusLeft($RADIUS_TABLE_GENERAL)
				th:last-of-type
					+setBorderRadiusRight($RADIUS_TABLE_GENERAL)
		tbody
			tr:first-of-type
				td:first-of-type
					+setBorderRadiusTopLeft($RADIUS_TABLE_GENERAL)
				td:last-of-type
					+setBorderRadiusTopRight($RADIUS_TABLE_GENERAL)
			tr:last-of-type
				td:first-of-type
					+setBorderRadiusBottomLeft($RADIUS_TABLE_GENERAL)
				td:last-of-type
					+setBorderRadiusBottomRight($RADIUS_TABLE_GENERAL)
	table.TableListDetails
		padding: 0px
		margin-top: $SPACE_LITTLE
		margin-bottom: 0px
		max-width: $WIDTH_TABLE_ATTACHMENT
		table-layout: fixed
		td
			padding: 0px
			figure
				margin: 0px
				img
					margin-top: $SPACE_TINY
		td:first-of-type
			text-align: right
			padding-left: 0px
		td:nth-child(1)
			width: $WIDTH_TABLE_ATTACHMENTSMALL
		td:nth-child(2)
			width: $WIDTH_TABLE_ATTACHMENTBIG
			overflow: hidden
			text-overflow: ellipsis
		td:nth-child(3)
			width: $WIDTH_TABLE_ATTACHMENTMEDIUM
		td:nth-child(4)
			width: $WIDTH_TABLE_ATTACHMENTSMALL
			padding-right: 0px
	table.TableListDetailsAttachment
		td:last-of-type
			position: relative
			display: flex
			flex-flow: column wrap
			align-items: center
	ul.ListTableTicket, ul.ListTableConversation
		flex-direction: column
		li
			width: 100%
			padding-top: $SPACE_SMALL
			padding-left: $SPACE_MEDIUM
			padding-right: $SPACE_MEDIUM
			padding-bottom: $SPACE_SMALL
			display: flex
			align-items: center
			+setBoxSizing(border-box)
			img
				margin-right: $SPACE_MEDIUM
				width: $SIZE_ICON_LDPI
				height: $SIZE_ICON_LDPI
			div.DivTicketItem, div.DivConversationGeneral
				display: flex
				flex-direction: column
				flex-grow: 1
				div.DivTicketSubItem, div.DivConversationItem
					display: flex
					justify-content: space-between
					dl
						margin: 0px
						flex-grow: 1
						flex-basis: 0
					div.DivConversationStatus
						display: flex
						align-items: flex-start
					div.DivConversationHeader
						display: flex
						flex-flow: row wrap
						align-items: center
						div.DivConversationProfile
							+setBorderRadius(50%)
							span.SpanChatProfileNameInitial
								width: $SPACE_BIG
								height: $SPACE_BIG
								display: flex
								align-items: center
								justify-content: center
						div.DivConversationSubHeader
							span.SpanConversationTitle
								max-width: 200px
					div.DivTicketTail
						span.SpanTicketAlert
							width: 7px
							height: 17px
							background-color: red
							margin-bottom: 0
							+setBorderRadius(50%)
							border-style: solid
			div.DivTicketSubItem:first-of-type, div.DivConversationItem:first-of-type
				margin-bottom: $SPACE_TINY
			div.DivTicketSubItem:last-of-type, div.DivConversationItem:last-of-type
				margin-top: $SPACE_TINY
		li:first-of-type
			+setBorderRadiusTop($RADIUS_LAYOUT_FORM)
		li:end-of-type
			+setBorderRadiusBottom($RADIUS_LAYOUT_FORM)
	ul.ListTableConversation
		li
			+setBorderRadius($RADIUS_LAYOUT_FORM)
		li.LiConversationContainerRight, li.LiConversationContainerLeft
			margin-top: $SPACE_MEDIUM
			width: 80%
			+setBoxSizing(border-box)
		li.LiConversationContainerRight
			align-self: flex-end
			div.DivConversationItem
				div.DivConversationHeader
					span
						text-align: right
					div.DivConversationProfile
						margin-left: $SPACE_MEDIUM
						margin-right: 0px
		li.LiConversationContainerLeft
			align-self: flex-start
			flex-direction: row-reverse
			div.DivConversationItem
				flex-direction: row-reverse
				div.DivConversationHeader
					flex-direction: row-reverse !important
					span.SpanConversationDate
						text-align: left
					div.DivConversationProfile
						margin-left: 0px
						margin-right: $SPACE_MEDIUM
		li:first-of-type
			margin-top: 0px

div.DivSubDynamicContainer
	div.DivTableContainer
		margin-left: 0px

div.DivCenterGeneral
	div.DivCenterContainer
		div.DivTableContainer
			margin-left: 0px
	div.DivDynamicContainer
		margin-left: 0px

// TABLE - END


// LOADING - START

div#divCurtainLoading
	position: fixed
	top: 0px
	left: 0px
	right: 0px
	bottom: 0px
	display: none
	z-index: 10
	div#divLoadingBar
		width: 0%
		height: $HEIGHT_LOADING_BAR
		display: none
	div#divLoadingMessage, img#imageLoadingCircle
		display: flex
		flex-direction: column
		justify-content: center
		align-items: center
	div#divLoadingMessage
		width: 100%
		height: 100%
		img#imageLoadingCircle
			width: 100px
			height: 100px
			background-color: none
		span#spanLoadingPercentage
			margin-top: 30px
		div.DivLoadingDotFallContainer
			display: flex
			flex-direction: row
			div.DivLoadingDotFall
				position: relative
				left: -9999px
				width: 20px
				height: 20px
				margin-right: 10px
				border-radius: 20px
				background-color: #FFF
				color: #FFF
				box-shadow: 9999px 0 0 0 #FFF
				animation: dotFalling 1.5s infinite linear
				&:nth-child(1)
					animation-delay: .2s
				&:nth-child(2)
					animation-delay: .3s
				&:nth-child(3)
					animation-delay: .4s
				&:nth-child(4)
					animation-delay: .5s
				&:nth-child(5)
					animation-delay: .6s
				&:nth-child(6)
					animation-delay: .7s

// LOADING - END

div#divBodyDashboard
	div#divMainDashboard
		width: 100%
		height: 100%
		margin-top: $SPACE_MEDIUM
	div#divMainDashboard
		display: flex
		flex-wrap: nowrap
	div.DivCenterContainer
		margin-top: $SPACE_HUGE
		display: block
		width: 75%
		position: relative
		margin-left: auto
		margin-right: auto
		div.DivForm
			div.DivHeaderHeadline
				display: flex
				justify-content: space-between
				div.DivHeaderStatus
					display: flex
					align-items: flex-start
					justify-content: space-between
					span
						text-align: center
						margin-left: $SPACE_LITTLE
						margin-right: $SPACE_LITTLE
						margin-top: 0px
						padding: $SPACE_LITTLE
						+setBorderRadius($RADIUS_BUTTON_GENERAL)
					span:first-of-type
						margin-left: 0px
					span:last-of-type
						margin-right: 0px
				div.DivSubHeaderHeadline
					label
						margin: 0
					h2
						margin-top: 5px
						margin-bottom: 5px
			fieldset
				margin-top: 0px
				div.DivForm2Column
					div.DivHeaderInfo
						margin-top: $SPACE_LITTLE
						display: flex
						justify-content: space-between
						dl
							margin: 0px
							flex-grow: 1
							flex-basis: 0
							dd
								margin: 0
				div.DivFormHorizontalContainer
					div.DivHeaderList
						align-self: flex-start
						ul
							li
								padding: 5px 10px
								width: fit-content
								margin-right: 0
								margin-bottom: 5px
								margin-top: 5px
								span
									margin: 0
								+setBorderRadius(9px)
							li:first-of-type
								margin-top: 0px
							li:last-of-type
								margin-bottom: 0px

div.DivProfileGeneral
	display: flex
	justify-content: space-between
	div.DivProfileInfo
		display: flex
		flex-direction: row-reverse
		div.DivProfileInfoDetails
			display: flex
			flex-direction: column
			justify-content: center
		div.DivProfilePhoto
			margin-right: $SPACE_SMALL
			+setBorderRadius(50%)
			span.SpanChatProfileNameInitial
				width: $SPACE_BIG
				height: $SPACE_ENORMOUS
				padding-top: $PADDING_SPAN_CONVERSATIONPROFILE
				display: flex
				justify-content: center

// CALCULATE PREMI FORM - START

form.CalculateForm
	width: 50%
	margin-left: auto
	margin-right: auto
	div.DivForm
		padding: $SPACE_MEDIUM
		fieldset
			border: none
			padding: 0px
			margin-bottom: $SPACE_LITTLE
			margin-top: $SPACE_LITTLE
			div.DivInputText
				margin: 0px
				padding: 0px
				+setBorderRadius(0px)
				display: flex
				align-items: flex-end
				span.SpanRupiah
					margin-right: 30px
		fieldset:last-of-type
			margin-bottom: 0px

// CALCULATE PREMI - END


// PRODUCT INFORMATION - START

div.DivTableContainer
	ul.ListTableThumbnails
		li
			div.DivButtonNextCalculate
				display: flex
				justify-content: center
				div.DivButtonHide
					display: none

// PRODUCT INFORMATION - END

/* THIRD PARTY  START */

// SINGLE DROPDOWN - START

div.DivFormSingleDropdown
	.ng-select
		width: 100%
		.ng-select-container
			height: 40px
			width: 100%
			border-radius: $SPACE_LITTLE
			background-repeat: no-repeat
			background-position: right
			background-size: $SPACE_HUGE + 6
			.ng-value-container
				flex-wrap: wrap
				width: 100%
				.ng-value
					padding-right: $SPACE_TINY
					font-size: 14px
					margin: $SPACE_SMALL 0px $SPACE_SMALL $SPACE_LITTLE
					border-radius: $SPACE_TINY
					margin-right: $SPACE_TINY
					display: flex
					line-height: 0px
					align-items: center
				.ng-input>input
					padding-left: $SPACE_LITTLE
	.ng-clear-wrapper
		margin-right: $SPACE_LARGE
		height: $SPACE_MEDIUM
	.ng-dropdown-panel
		.ng-dropdown-panel-items
			.ng-option
				box-sizing: border-box
				cursor: pointer
				display: block
				margin-bottom: -$SPACE_SMALL
				margin-left: $SPACE_LITTLE

//SINGLE  DROPDOWN - END

// MULTIPLE DROPDOWN - START

div.DivFormMultipleDropdown
	.ng-select
		width: 100%
		.ng-select-container
			width: 100%
			border-radius: $SPACE_LITTLE
			background-repeat: no-repeat
			background-position: right
			background-size: $SPACE_HUGE
			.ng-value-container
				flex-wrap: wrap
				width: 100%
				padding: 5px
				.ng-value
					padding-right: $SPACE_TINY
					font-size: 14px
					margin: $SPACE_TINY
					border-radius: $SPACE_TINY
					display: flex
					line-height: 0px
					align-items: center
					height: fit-content
					.ng-value-icon
						display: inline-block
						padding: $SPACE_TINY $SPACE_TINY
						padding-top: $SPACE_LITTLE
						background-color: #F7931E
					.ng-value-icon.left
						// margin-right: $SPACE_TINY
						margin-left: -$SPACE_LITTLE
						margin-top: 0px
						margin-bottom: 0px
						border-radius: 50%
						height: $SPACE_MEDIUM
						border: none
						opacity: 0%
					.ng-value-label
						margin-left: 5px
						white-space: normal
						line-height: initial
						padding-top: $SPACE_LITTLE
						padding-bottom: $SPACE_LITTLE
					span
						margin-bottom: 0px
						margin-top: 0px
				.ng-value::before
					content: "-"
					width: $SPACE_LITTLE
					height: $SPACE_LITTLE
					margin-left: 5px
					margin-right: -10px
					background-color: #F7931E
					+setBorderRadius($SPACE_LITTLE)
					color: white
					text-align: center
					padding-left: 5px
					padding-right: 5px
					padding-top: 8px
					padding-bottom: 2px
					font-size: 2em
				.ng-input>input
					margin-left: $SPACE_TINY
		.ng-clear-wrapper
			position: relative
			margin-top: auto
			margin-bottom: auto
			margin-right: 25px
			padding: 0px
			span
				margin-bottom: 0px
				margin-top: 0px
				padding-left: 7px
	.ng-dropdown-panel
		.ng-dropdown-panel-items
			.ng-option
				box-sizing: border-box
				cursor: pointer
				display: block
				margin-bottom: -$SPACE_SMALL
				margin-left: $SPACE_LITTLE

// MULTIPLE DRODOWN - END

// MULTIPLE DROPDOWN - START

div.DivFormMultipleDropdown
	.ng-select
		width: 100%
		.ng-select-container
			width: 100%
			border-radius: $SPACE_LITTLE
			background-repeat: no-repeat
			background-position: right
			background-size: $SPACE_HUGE
			.ng-value-container
				flex-wrap: wrap
				width: 100%
				padding: 5px
				.ng-value
					padding-right: $SPACE_TINY
					font-size: 14px
					margin: $SPACE_TINY
					border-radius: $SPACE_TINY
					display: flex
					line-height: 0px
					align-items: center
					height: fit-content
					.ng-value-icon
						display: inline-block
						padding: $SPACE_TINY $SPACE_TINY
						padding-top: $SPACE_LITTLE
						background-color: #F7931E
					.ng-value-icon.left
						// margin-right: $SPACE_TINY
						margin-left: -$SPACE_LITTLE
						margin-top: 0px
						margin-bottom: 0px
						border-radius: 50%
						height: $SPACE_MEDIUM
						border: none
						opacity: 0%
					.ng-value-label
						margin-left: 5px
						white-space: normal
						line-height: initial
						padding-top: $SPACE_LITTLE
						padding-bottom: $SPACE_LITTLE
					span
						margin-bottom: 0px
						margin-top: 0px
				.ng-value::before
					content: "-"
					width: $SPACE_LITTLE
					height: $SPACE_LITTLE
					margin-left: 5px
					margin-right: -10px
					background-color: #F7931E
					+setBorderRadius($SPACE_LITTLE)
					color: white
					text-align: center
					padding-left: 5px
					padding-right: 5px
					padding-top: 8px
					padding-bottom: 2px
					font-size: 2em
				.ng-input>input
					margin-left: $SPACE_TINY
		.ng-clear-wrapper
			position: relative
			margin-top: auto
			margin-bottom: auto
			margin-right: 25px
			padding: 0px
			span
				margin-bottom: 0px
				margin-top: 0px
				padding-left: 7px
	.ng-dropdown-panel
		.ng-dropdown-panel-items
			.ng-option
				box-sizing: border-box
				cursor: pointer
				display: block
				margin-bottom: -$SPACE_SMALL
				margin-left: $SPACE_LITTLE

// MULTIPLE DRODOWN - END

// NGX-MAT-TIMEPICKER START

div.DivForm, form div.DivForm fieldset
	table.ngx-mat-timepicker-table
		tbody.ngx-mat-timepicker-tbody
			td.ngx-mat-timepicker-spacer
				padding-top: $SPACE_LITTLE
			div, mat-form-field
				margin: 0px
				padding: 0px
				tr
					td
						height: 35px
						div.mat-form-field-wrapper


// NGX-MAT-TIMEPICKER END


// CONTAINER GOOGLE MAPS PLATFORM START

	// CARD FOR BRANCH MENU DETAIL START //

div.DivFormBranch
	border-radius: 12px
	div.DivBranchCardInformation
		display: flex
		align-content: center
		margin-top: $SPACE_LITTLE
		img#imageLocation, img#imagePhone, img#imageEmail 
			width: $SPACE_MEDIUM
			height: $SPACE_MEDIUM
			margin-right: $SPACE_LITTLE

	// CARD FOR BRANCH MENU DETAIL END //


	// CARD INSIDE GOOGLE MAPS FOR BRANCH MENU DETAIL START //

#divMainDashboard
	div.DivFormCardInside
		position: absolute 
		margin: $SPACE_MEDIUM
		width: 30%
		z-index: 1
		div.DivFormBranch
			div.DivBranchCardInformation
				img
					border-radius: 50%
					padding: $SPACE_TINY
	div.DivFormNoBorder
		google-map
			.map-container
				border-radius: $SPACE_SMALL


	// CARD INSIDE GOOGLE MAPS FOR BRANCH MENU DETAIL START //
	

// CONTAINER GOOGLE MAPS PLATFORM END


// BRIMO START

div.DivBodyGeneral
	margin-top: 0px
	header.HeaderProduct
		width: 100%
		margin-top: 0
		display: flex
		justify-content: center
		align-items: center
		padding-top: $SPACE_MEDIUM
		padding-bottom: $SPACE_MEDIUM
		.ButtonArrowLeft
			background-size: $SPACE_MEDIUM
			margin-left: $SPACE_MEDIUM
	div#divMainDashboard
		flex-direction: column
		margin-top: 0px !important
		div.DivBackgroundProduct, div.DivBackgroundPucrhasingDetail
			display: flex
			align-items: center
			background-repeat: no-repeat
			background-image: url($URL_SHAPE_RESOURCE + "shape_background.svg")
			background-size: cover
			width: 100%
			height: 390px
			margin-top: 0px
			flex-direction: column
			background-position: bottom
		input[type=button].ButtonNext, input[type=button].ButtonNextFirstCard , input[type=button].ButtonNexPurchasing, input[type=button].ButtonPurchasingSummary, input[type=button].ButtonNextFirstCardDisable
			margin: 0 20px 0 20px
			border: none
			border-radius: $SPACE_LITTLE
			margin-top: $SPACE_MEDIUM
			height: 40px
		input.InputUppercase
			text-transform: uppercase !important
		input.InputUppercase::placeholder
			text-transform: capitalize
		.ButtonBack
			border: none
			background-color: inherit
			padding: 10px
			margin-top: 10px
			font-size: 16px !important
			cursor: pointer
			display: inline-block
			font-size: 14px
			color: #F89621
			font-family: $URL_FAMILYTERTIARY_TERTIARY
		div.DivWrapperListFormCard
			margin-top: 20px
			div.DivContentFirstCard
				div.DivFirstCard
					div.DivDisable
						label
							color: #888888
						div.DivPremiumSimulation
							input::placeholder
								color: #888888
					div.DivCardFormContainer
						margin: 0 !important
		div.DivRadioButtonContainer
			display: flex
			margin-top: 5px
			margin-bottom: 5px
			div.DivRadioButton
				width: 21px
				height: 21px
				border-style: solid
				border-width: 2px
				+setBorderRadius(50%)
				align-items: center
				align-content: center
				justify-content: center
				border-color: #F89621
				input[type=radio]
					display: none
				label
					margin: 0px
					width: 0px
					height: 0px
					+setBorderRadius(50%)
				input[type=radio]:checked + label
					width: 70%
					height: 70%
					background-color: #F89621
					margin-left: 2px
					margin-bottom: 3px
			div.DivRadioButton + label
				margin-right: $SPACE_MEDIUM
			div.DivRadioButtonMerge
				display: flex
				align-items: center
		div.DivPriceValidation
			margin-left: 40px
			font-family: $URL_FAMILYTERTIARY_SECONDARY
			font-size: 14px
			margin-right: 20px
			ul
				line-height: 25px
				margin-top: 10px

// BRIMO END


// PRODUCT DETAIL START

div.DivContentPackage,div.DivContentFirstCard, div.DivCardInformation, div.DivCardInformationPurchasing
	display: flex
	flex-direction: column
	text-align: left
	// width: 350px
	margin: $SPACE_MEDIUM auto
	margin-top: 50px
	+setBorderRadius(10px)
	img
		display: block
		margin: 15px auto
		width: 150px
	div.DivHeaderPackage
		display: flex
		width: 100px
	hr
		margin-top: 0px
	div.DivImgForCheck, div.DivImgForCheckOverflow
		margin-top: 0px
		width: 100%
		display: flex
		align-items: center
		height: 100%
		margin-bottom: 10px
		label
			margin-right: 20px
		img.ImgCheck, img.ImgUnCheck
			width: $SPACE_MEDIUM
			height: $SPACE_MEDIUM
			margin-left: $SPACE_MEDIUM
			margin-right: 10px
			margin-top: 10px
			margin-bottom: 10px
			padding: 3px
			border-radius: 50%
	div.DivImgForCheckOverflow
		margin-bottom: -100px
		img.ImgUnCheckOverFlow
			padding: 3px
			width: $SPACE_MEDIUM
			height: $SPACE_MEDIUM
			margin-left: $SPACE_MEDIUM
			border-radius: 50%
			margin-right: 0
			margin-top: 10px
		label
			margin-left: 10px !important

div.DivPaginateContainer
	text-align: center

// PRODUCT DETAIL END	



// BRIMO PRODUCT LIST START

div#divBodyDashboard
	div#divMainDashboard
		div.DivContentPackage
			+boxShadow(0, 8px, 8px, 0, rgba(0, 0, 0, 0.2))
			margin-top: $SPACE_MEDIUM
			margin-left: 16px
			margin-right: 16px
			margin-bottom: -10px
			padding-bottom: 10px
			span
				font-size: 14px
				margin-right: $SPACE_MEDIUM
			hr
				margin: 0px 20px 10px 20px
			div.DivHeaderPackage
				width: 200px
				display: flex
				align-items: center
				div.DivDetailPackage
					margin-top: -20px
			div.DivSingleLine
				margin-bottom: 0px
				gap: 10px
			div.DivThreeLine
				margin-top: 0px
				margin-bottom: 20px
		div.DivContentPackageAsmik
			+boxShadow(0, 0, 8px, 0, rgba(0, 0, 0, 0.2))
		div.DivContentTotalPremi
			+boxShadow(0, 0, 8px, 0, rgba(0, 0, 0, 0.2))
			height: 55px
			justify-content: space-between
			align-items: center
			margin-top: 30px
			margin-left: 16px
			margin-right: 16px
			padding-left: 20px
			padding-right: 20px
			padding-bottom: 0
		div.DivWrapperList
			display: flex
		div.DivSelectPackage
			+boxShadow(0, 0px, 8px, 0, rgba(0, 0, 0, 0.2))
			margin-left: 15px
			margin-right: 15px
			margin-top: 10px
			margin-bottom: 20px
			padding: 15px
			padding-left: 20px
			padding-right: 20px
			border-radius: 5px
			margin-bottom: -20px
			div.DivWrapperSelectedPackage
				display: flex
				justify-content: space-between
				div.ContainerLabelPackage
					display: flex
					flex-direction: column
					justify-content: center
					span
						font-size: 14px
						color: #6D6E71
						font-family: $URL_FAMILYTERTIARY_SECONDARY
						font-weight: 600
					label
						margin-left: 0px
						margin-top: 0px
						font-size: 16px
						font-family: $URL_FAMILYTERTIARY_TERTIARY
					div.DivIconPackage
						display: flex
						img
							width: 50px
						div.DivContainerLabelPackage
							display: flex
							flex-direction: column
							justify-content: center
							margin-left: 10px

// BRIMO PRODUCT LIST END



// BRIMO GENERAL START

div.DivSideBySide
	display: flex
	justify-content: space-between
	padding: 0px 20px
	overflow-wrap: anywhere
	label.labelTotal, span.labelTotal
			font-family: $URL_FAMILYTERTIARY_TERTIARY

// BRIMO GENERAL END


// BRIMO LANDING PAGE START

div.DivBodyGeneral
	div#divMainDashboard
		div.DivBackgroundAsriLandingPage, div.DivBackgroundOtoLandingPage, div.DivBackgroundBicycleLandingPage
			background-repeat: no-repeat
			background-cover: cover
			width: 100%
			height: 100%
			margin-top: 0px
			background-size: 100%
			margin-bottom: -30px
			div.DivButtonPage
				display: flex
				flex-direction: column
				.ButtonNext
					margin: 0px 60px 0px 60px
			div.DivContentDetailPage
				margin-top: 100px
		div.DivContainerError
			display: flex
			justify-content: center
			align-items: center
			flex-direction: column
			margin-top: 70px
			img.ImgErrorConection, img.ImgInternalServerError, img.ImgSessionExpired, img.ImgPurchasingSuccess
				content:  url($URL_SHAPE_RESOURCE + "shape_no_conection.svg")
				width: 250px
				margin-bottom: 20px
			img.ImgSessionExpired
				content:  url($URL_SHAPE_RESOURCE + "shape_session_expired.svg")
			img.ImgInternalServerError
				content:  url($URL_SHAPE_RESOURCE + "shape_internal_server.svg")
				margin-bottom: 0px
			img.ImgPurchasingSuccess
				content:  url($URL_SHAPE_RESOURCE + "shape_purchasing_success.svg")
			label
				margin-left: 40px
				margin-right: 40px
				margin-bottom: 30px
				text-align: center
			label.LabelSessionExpired
				margin-bottom: -10px
			p
				font-size: 20px
				color: #F89621
		div.DivNotificationClipboard
			width: 100%
			height: 40px
			color: white
			background-color: #0AB970
			display: flex
			justify-content: center
			display: none
			label
				font-size: 14px
				color: white
				font-family: $URL_FAMILYTERTIARY_SECONDARY
				margin-top: 5px
		label.labelTotal, span.labelTotal
			font-family: $URL_FAMILYTERTIARY_TERTIARY

// BRIMO LANDING PAGE END


// BRIMO POLCYHOLDER START

div#divBodyDashboard	
	div.DivMainFirstCard
		div.DivWrapperListFormCard, div.DivWrapperListCardPremiumResult, div.DivWrapperPurchasingDetail, div.DivWrapperListFormCardAsmikSupportingDocument
			display: flex
			flex-direction: column
			padding: 0px 20px 0px 20px
		div.DivContentFirstCard, div.DivCardInformation, div.DivCardInformationPurchasing
			margin-right: 60px
			margin-top: $SPACE_MEDIUM
			width: 100%
			display: flex
			flex-direction: row
			div.DivFirstCardPremiumResult
				padding: 5px !important
			div.DivFirstCard
				display: flex
				flex-direction: column
				padding: 15px
				flex: 50%
				span.spanErrorMessage, span.spanErrorMessageEmail, span.spanErrorMessageWithMarginTop
					color: red
					// display: none
					font-size: 12px
					font-family: $URL_FAMILYTERTIARY_PRIMARY
					position: relative
					margin-bottom: 5px !important
				span.spanErrorMessageEmail
					margin-bottom: 10px !important
				span.spanErrorMessageWithMarginTop
					margin-top: -10px
				input, textarea
					background-color: transparent
					border: none
					margin-bottom: 5px
					margin-left: -2px
				::placeholder
					color: black
					font-size: 16px
				div.DivRadioButtonContainer
					display: flex
					gap: $SPACE_LITTLE
				textarea
					background-color: transparent
					border-radius: 5px
					margin: 5px 0px 10px 0px
					margin-bottom: 5px !important
					height: 80px
				.TextAreaAddress::placeholder
					padding: $SPACE_LITTLE
					font-family: $URL_FAMILYPRIMARY_PRIMARY !important
					font-size: 14px
				select
					background-color: transparent
					background-image: none
					width: 55px
					border: none
					border-bottom: 1px solid black
					color: black
					margin-right: $SPACE_LITTLE
					margin-bottom: $SPACE_LITTLE
				select:nth-child(2)
					width: 100px
				select:nth-child(3)
					width: 70px
				div.InputSideBySide
					div.DivInputWrapingSidebySide
						input
							width: 100%
						span
							color: #F89621
						span#spanRegionNumber
							color: black
				div.DivContainerCamera
					div.DivWrapperCamera
						background-image:  url($URL_ICON_RESOURCE + "icon_image_capture.svg")
						background-size: 120px
						background-repeat: no-repeat
						background-position: center
						width: 100%
						height: 200px
						background-color: #EEF2F6
						margin-bottom: 15px
			div#divFirstCardBicycle
				input, textarea
					margin-bottom: 15px !important
				div.DivDropdownPremiumSimulation
					div.DivDialogContainer
						height: auto
						margin-bottom: 15px !important
		input[type=button].ButtonNextFirstCard, input[type=button].ButtonNextFirstCardDisable
			margin: $SPACE_MEDIUM 20px 0 20px
			font-size: 16px	

// BRIMO POLCYHOLDER END


// BRIMO ASRI PREMIUM SIMULATION START

div#divMainDashboard
	div.DivWrapperListFormCard
		div.DivContentFirstCard
			div.DivFirstCard
				div.DivPremiumSimulation
					div.DivContainerPrice
						height: 64px
						background-color: #F1F2F2
						border-radius: 10px
						padding: 10px
						height: 100%
						input
							margin-bottom: 0px
							border-bottom-color: transparent
						span
							color: black
							margin-right: 3px
						span.spanErrorMessage
							color: red
							// display: none
							font-size: 12px
							font-family: $URL_FAMILYTERTIARY_PRIMARY
							position: relative
						label
							margin-top: 0px
							color: #A9ACB5
					input
						width: 95%
					textarea
						width: 100%
						padding: 10px
					textarea::placeholder
						color: #888888
						font-family: $URL_FAMILYTERTIARY_PRIMARY
						font-size: 16px
	div.DivCardInformation
			margin-top: $SPACE_LITTLE
			div.DivFirstCardPackage
				margin-right: 16px
				margin-left: 16px
				div.DivWrapImgInformation
					margin-left: 20px
					margin-top: 10px
					margin-bottom: -10px
					display: flex
					img.ImgInformation
						margin: 0px 0px 0px 20px
					p
						margin: 0px
						margin-left: 0px
				label
					margin-bottom: 10px
			div.DivWrapImgInformation
				display: flex


					
// BRIMO ASRI PREMIUM SIMULATION END


// BRIMO ASRI PREMIUM RESULT START

div#divMainDashboard
	div.DivWrapperListCardPremiumResult, div.DivWrapperPurchasingDetail
		div.DivContentFirstCard
			margin-left: 0px !important
			div.DivPremiumResult
				hr
					margin-top: -10px
				div.DivContainerFormBottom
					display: flex
					padding: 16px
					padding-top: 5px
					div.DivFormBottom
						display: flex
						flex-direction: column

// BRIMO ASRI PREMIUM RESULT END


// BRIMO ASRI INSURED START

div#divMainDashboard
	div.DivWrapperListFormCard
		div.DivContentFirstCard
			div.DivFirstCard
				div.DivCheckBoxContainer:nth-child(9)
					margin-top: 0px
				div.DivCheckBoxContainer
					display: flex
					gap: $SPACE_LITTLE
					margin: $SPACE_LITTLE 0px 10px 0px
					input[type=checkbox]
						display: none
					input[type=checkbox] + label
						width: $SPACE_MEDIUM
						height: $SPACE_MEDIUM
						border-radius: 3px
						cursor: pointer
						background-color: transparent
					input[type=checkbox]:checked + label:after
						position: relative
						top: -3px
						left: 0px
						content:  url($URL_INPUT_RESOURCE + "input_checkbox.svg")
						padding-left: 2px
						padding-right: 5px
						padding-bottom: 0px
						font-size: 13px
						width: 100%
						max-width: 100px
				div.DivDisableInput
					display: flex
					flex-direction: column
					label
						color: #888888
					span
						color: #888888
					hr
						margin: 0px 0px 10px
// BRIMO ASRI INSURED END


// BRIMO ASRI SUPPORTING DOCUMENT START

div#divMainDashboard
	div.DivWrapperListFormCard
		div.DivContentFirstCard
			div.DivFirstCard
				div.DivDocumentCard
					width: 100%
					height: 100px
					border-radius: 5px
					margin-top: $SPACE_LITTLE
					margin-bottom: $SPACE_MEDIUM
					img.ImageUpload
						width: 100%
						height: 100px
						margin-top: 0px
				input.ImgCapture, input.ImgCaptureForDisplay
					width: 100%
					height: 200px
					background-color: #F1F2F2
					border-radius: 5px
					opacity: 10
				input.ImgCapture::-webkit-file-upload-button, input.ImgCaptureForDisplay::-webkit-file-upload-button
				input.ImgCaptureForDisplay
					margin-top: -205px
					position: absolute
					width: 77%
					background-repeat: no-repeat
					background-size: contain
					background-position: center
					color: transparent
				input.ImgCaptureForDisplay::-webkit-file-upload-button
					visibility: hidden
				input.ImgCapture::before, input.ImgCaptureForDisplay::before
					content:  ""
					margin-top: 0px
					display: inline-block
					background-color: #F1F2F2
					border-radius: 5px
					outline: none
					white-space: nowrap
					-webkit-user-select: none
					cursor: pointer
					background-image: url($URL_ICON_RESOURCE + "icon_image_capture.svg")
					background-repeat: no-repeat
					background-position: center
					background-position-y: 60px
					height: 200px
					width: 100%
				input.ImgCaptureForDisplay::before
					opacity: 0
									

// BRIMO ASRI SUPPORTING DOCUMENT END


// BRIMO ASRI PURCHASING DETAIL START

div#divMainDashboard
	div.DivBackgroundPucrhasingDetail
		height: 250px !important
	div.DivWrapperPurchasingDetail
		margin-top: -240px
		background-color: transparent
		div.DivAgreement
			width: 100%
			height: 400px
			padding: $SPACE_MEDIUM
			border-radius: $SPACE_LITTLE
			overflow-y: scroll
			margin-top: $SPACE_MEDIUM
			margin-bottom: 0px
			label
				margin-left: $SPACE_MEDIUM
			ol
				margin: $SPACE_NONE $SPACE_MEDIUM
		p.pFontSPPA
			text-align: center
			width: 200px
			margin-top: 0px
	input[type=button].ButtonNexPurchasing
		margin: 0px 20px 0px 20px !important
	div.DivCheckBoxContainerPurchasing
		display: flex
		gap: $SPACE_LITTLE
		margin: $SPACE_LITTLE 0px 10px 20px
		margin-top: 20px !important
		input[type=checkbox]
			display: none
		input[type=checkbox] + label
			width: $SPACE_MEDIUM
			height: $SPACE_MEDIUM
			border-radius: 50%
			cursor: pointer
			background-color: transparent
		input[type=checkbox]:checked + label:after
			position: relative
			top: -4px
			left: -1px
			content:  url($URL_INPUT_RESOURCE + "input_checkbox.svg")
			padding-left: 3px
			padding-right: 5px
			padding-bottom: 2px
			font-size: 12px
			width: 100%
			max-width: 100px
			border-radius: 50%
	div.DivCheckBoxContainerPurchasing:nth-of-type(4)
		margin-top: 0px !important
	div.DivContainerCardInformation
		padding: 0px 20px 0px 20px
		margin-top: -10px
		div.DivCardInformationPurchasing
			width: 100%
			margin-right: $SPACE_MEDIUM
			div.DivWrapImgInformation
				display: flex
				img.ImgInformation
					content: url($URL_ICON_RESOURCE + "icon_info.svg")
					width: $SPACE_MEDIUM
					margin: 0px
					margin-bottom: $SPACE_LITTLE
					margin-right: $SPACE_LITTLE
	div.DivSubTitleAgreement
		margin-left: 40px
		margin-top: 10px
		ul
			margin-right: 20px
			li
				margin-left: 30px
				font-size: 14px
				margin-top: -10px
				line-height: 18px
			li:nth-of-type(2)
				margin-top: 5px
				margin-bottom: 10px

// BRIMO ASRI PURCHASING DETAIL END


// BRIMO ASRI PURCHASING SUMMARY START

div#divMainDashboard
	div.DivSideBySidePurchaseSummary
		display: flex
		justify-content: space-between
		padding: 0px 20px
		padding-left: 0px
		padding-right: $SPACE_MEDIUM
		text-align: center
		// label.labelTotal, span.labelTotal
		// 	// color: red
		// 	font-family: $URL_FAMILYTERTIARY_TERTIARY
	hr
		margin: $SPACE_MEDIUM 20px 0px 20px
		height: 2px
	div.DivCardTimer, div.DivContainerBriva
		display: flex
		flex-direction: column
		justify-content: center
		align-items: center
		margin: $SPACE_MEDIUM
		padding-bottom: $SPACE_MEDIUM
		border-radius: $SPACE_LITTLE
		div.ContainerTimer
			display: flex
			div.DivWrapperTimer
				span
					margin-right: 5px

	div.DivContainerBriva
		flex-direction: row
		justify-content: space-between
		margin-top: $SPACE_LITTLE
		padding-right: 25px
		padding-left: 25px
		input[type=button].ButtonCopyForBriva
			font-size: 12px
			padding: 12px 30px
			border-radius: 5px
			margin-top: $SPACE_MEDIUM
			border: none
	input[type=button].ButtonPurchasingSummary
		margin-top: 20px !important
	
	div.DivWrapperListFormCard
		div.DivCardInformation
			div.DivFirstCard

// BRIMO ASRI PURCHASING SUMMARY END



// BRIMO OTO INSURED START

div#divMainDashboard
	div.DivWrapperListFormCard
		div.DivContentFirstCard
			div.DivFirstCard
				div.DivDropdownPremiumSimulation
					div.DivDialogContainer
						p
							font-weight: bold
							margin-left: -5px !important
					select
						width: 100%
						margin-top: 5px
						padding: 0px 0px 10px 0px
						margin-left: -5px
						option
							margin-left: 0px
					div.DivDialogContainer
						div.DivDialogImageContainer
							img.ImageDialogDropdown
								content: url($URL_INPUT_RESOURCE + "input_dropdown.svg")
				input.InputPlateSuffix
					width: 100%
				input.InputPlateSuffix::placeholder
					margin-top: 0px
				
// BRIMO OTO INSURED END


// BRIMO DROPDOWN START

div#divBodyDashboard	
	div.DivMainFirstCard
		div.DivWrapperListFormCard
			div.DivContentFirstCard
				div.DivFirstCard
					div.InputSideBySide
						div.DivInputWrapingSidebySide
							display: flex	
							gap: 20px
							div.DivDropdownPremiumSimulation
								div.DivDialogContainer
									width: 100%
									display: flex
									flex-direction: row
									justify-content: space-between
									height: 40px
									border-bottom: solid 1px #CFCFCF
									margin-bottom: 10px
									p.ParagraphDialogDropdown
										color: #333333 !important
										font-family: "AvenirNextLTPro-Demi" !important
										margin: 0px !important
										display: flex
										padding-left: 5px
										flex-direction: column
										justify-content: center
										font-size: 16px
									div.DivDialogImageContainer
										display: flex
										flex-direction: column
										justify-content: center
										img.ImageDialogDropdown
											width: 20px
											height: 20px
											margin: 0
div.DivDropdownPremiumSimulation
	div.DivDialogContainer
		width: 100%
		display: flex
		flex-direction: row
		justify-content: space-between
		height: auto
		border-bottom: solid 1px #CFCFCF
		margin-bottom: 10px
		p.ParagraphDialogDropdown
			color: #333333 !important
			font-family: "Poppins-SemiBold"
			margin: 0px !important
			display: flex
			padding-left: 5px
			flex-direction: column
			justify-content: center
			font-size: 14px
		div.DivDialogImageContainer
			display: flex
			flex-direction: column
			justify-content: center
			img.ImageDialogDropdown
				width: 20px
				height: 20px
				margin: 0


div.DivContainerModal
	+boxShadow(0, -4px, 8px, 0, rgba(0, 0, 0, 0.2))
	position: fixed
	bottom: -100px
	width: 100%
	max-height: 500px
	overflow-y: scroll
	border-top-right-radius: 20px !important 
	border-top-left-radius: 20px !important
	background-color: #FFF
	z-index: 999
	div#divModalBottomListItem
		+boxShadow(0, -4px, 8px, 0, rgba(0, 0, 0, 0.2))
		position: fixed
		top: auto
		left: auto
		right: auto
		bottom: 0px
		padding-top: 20px
		padding-left: 20px
		padding-right: 20px
		padding-bottom: 0px
		width: 100%
		height: 360px
		max-height: 500px
		overflow-y: scroll
		border-top-right-radius: 20px
		border-top-left-radius: 20px
		background-color: #FFF
		z-index: 999
		form
			margin: 0
			div.DivListItem
				border-bottom: solid 1px #CFCFCF
				margin-bottom: 5px
				height: 45px
				display: flex
				flex-direction: column
				justify-content: center
				input[type=radio]
					display: none
				label
					font-family: "AvenirNext-Regular" !important
					padding: 20px
					margin-bottom: 10px
	
	div.DivContainerDate
		display: flex
		padding-left: 10px
		padding-top: 20px
		justify-content: center
		text-align: center
		gap: 20px
		div.DivModalPolicyHolderDay, div.DivModalPolicyHolderForMonth, div.DivModalPolicyHolderForYear
			bottom: 0px
			padding-bottom: 0px
			height: 360px
			max-height: 500px
			overflow-y: scroll
			z-index: 999
			form
				margin: 0
				div.DivListItem
					margin-bottom: 5px
					height: 45px
					display: flex
					flex-direction: column
					justify-content: center
					input[type=radio]
						display: none
					label
						font-family: "AvenirNext-Regular" !important
						padding: 20px
						margin-bottom: 10px
		div.DivModalPolicyHolderForMonth
			bottom: 0px
		div.DivModalPolicyHolderForYear
			bottom: 0px

div#divModalBottomListItem
	+boxShadow(0, -4px, 8px, 0, rgba(0, 0, 0, 0.2))
	position: fixed
	top: auto
	left: auto
	right: auto
	bottom: -1000px
	padding-top: 20px
	padding-left: 20px
	padding-right: 20px
	padding-bottom: 0px
	width: 100%
	height: 360px
	max-height: 500px
	overflow-y: scroll
	border-top-right-radius: 20px
	border-top-left-radius: 20px
	background-color: #FFF
	z-index: 999
	label.LabelPackageList
		margin-bottom: 30px
		font-family: $URL_FAMILYTERTIARY_TERTIARY
		color: #6D6E71
	form
		margin: 0
		div.DivListItem
			border-bottom: solid 1px #CFCFCF
			margin-bottom: 5px
			height: 45px
			display: flex
			flex-direction: column
			justify-content: center
			input[type=radio]
				display: none
			label
				font-family: "Poppins-Regular" !important
				padding: 20px
				margin-bottom: 10px
			label.labelForPackage
				font-family: $URL_FAMILYTERTIARY_TERTIARY
		div.DivListItemForDropdown
			div.DivContainerIconPackage
				position: relative
				img
					width: 60px !important
					margin-right: 20px
				span
					font-family: $URL_FAMILYTERTIARY_TERTIARY
				label.labelForPackage
					font-family: $URL_FAMILYTERTIARY_TERTIARY
			input[type=button]
				opacity: 0
				position: absolute
				margin-top: 10px
		hr
			color: #bbbbbb

div.DivModalAlertContainer
	display: flex
	justify-content: center
	align-content: center
	div.DivModalAlert,  div.DivModalAlertPolicy
		+boxShadow(0, -4px, 8px, 0, rgba(0, 0, 0, 0.2))
		position: fixed
		padding: 25px
		width: 340px
		height: 235px
		// max-height: 230px
		overflow-y: scroll
		border-radius: 10px
		background-color: #FFF
		z-index: 999
		margin-bottom: 250px
		div.DivCustomAlertPolicy
			display: flex
			justify-content: center
			align-items: center
			flex-direction: column
			margin-top: -5px
		div.DivCustomAlert,div.DivCustomAlertPolicy
			display: flex
			justify-content: center
			align-items: center
			flex-direction: column
			h3
				font-family: $URL_FAMILYTERTIARY_TERTIARY
				font-size: 18px
				color: #6D6E71
			label
				margin-top: 15px
				text-align: center
				font-size: 16px
				font-family: $URL_FAMILYTERTIARY_PRIMARY
			span
				font-family: $URL_FAMILYTERTIARY_TERTIARY
			div.DivButtonWrapperAlert
				display: flex
				justify-content: space-around
				gap: 20px
				margin-top: 15px
				margin-bottom: -10px
				input[type=button]
					border-radius: 10px
					padding: 10px 40px
					width: 120px
					border: 1px solid #F89621
					font-family: $URL_FAMILYTERTIARY_TERTIARY
				input[type=button]:nth-child(1)
					background-color: white
					padding-left: 30px
					color: #F89621
				input[type=button]:nth-child(2)
					background-color: #F89621
					padding-left: 40px
					color: white
	div.DivModalAlertPolicy
		width: 320px
		height: 255px
		h3
			font-size: 20px
	div.DivModalCustomAlertSupportingDocument
		height: 200px !important


// BRIMO DROPDOWN END


// SUPPORTING DOCUMENT OTO START

.cdk-overlay-container
	mat-dialog-container
		padding: 10px
		.mat-dialog-actions
			justify-content: center
			gap: 40px
			padding-bottom: 20px
			width: 500px
			button
				width: 100px
				border-radius: 5px
				background-color: #1078CA
				font-family: $URL_FAMILYTERTIARY_SECONDARY
				color: white
				border: none
				padding: 5px
div.DivContainerCapture
	height: 100%
	width: 100%
	background-color: #1F1D1D
	justify-content: center
	align-items: center
	display: flex
	flex-direction: column
	overflow: hidden
	div.DivFrameImage
		width: 120%
		height: 100%
		background-repeat: no-repeat
		position: absolute
		background-size: contain
		background-position: center
		// background-color: red
	div.DivContainerButton
		position: absolute
		span
			position: absolute
			color: white
			bottom: 22px
			left: -90px
			font-size: 18px
			font-family: $URL_FAMILYTERTIARY_SECONDARY
		button.ButtonCapture::before
			content: ""
			background-color: red
			width: 100px
			height: 0
			position: absolute
			top: 0px
			left: 0px
		button.ButtonCapture
			width: 70px
			height: 70px
			border-radius: 100%
			background-color: white
			margin-top: 40px
			margin-left: 0px
			outline: 2px solid #1078CA
.full-screen-modal
	max-width: unset !important
	width: 100%
	height: 100%
	.mat-dialog-container
		max-width: 100vw
		max-height: 100vh
		height: 100%
		width: 100%
		padding: 0px
		.mat-dialog-content
		max-height: unset !important

// SUPPORTING DOCUMENT OTO END

.noSelection
	-webkit-touch-callout: none
	-webkit-user-select: none
	-khtml-user-select: none
	-moz-user-select: none
	-ms-user-select: none
	user-select: none
	

/* THIRD PARTY  END */
