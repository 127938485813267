/* @charset "utf-8"; */
/* CSS Document */

// IMPORT - START

@import form-initialization-general

// IMPORT - END


// GENERAL - START

/* FORM - START */

form
	margin-bottom: 10px
form.FormInsertConversation
	div.DivConversationGeneral
		div.DivConversationContent
			textarea
				border-bottom-left-radius: 0px
				border-bottom-right-radius: 0px
				resize: none
				margin-bottom: 0px
				padding-bottom: 0px

div.DivForm, form div.DivForm fieldset
	input, textarea
		+setBoxSizing(border-box)
	input
		height: $HEIGHT_INPUT_SINGLELINE
	input, select, textarea
		margin-bottom: $SPACE_NARROW
		margin-top: $SPACE_NARROW
		width: 100%
		min-width: $WIDTH_INPUT_SQUARE
		padding-top: $PADDING_INPUT_EDGE
		padding-bottom: $PADDING_INPUT_EDGE
		padding-right: $PADDING_INPUT_SIDE
		padding-left: $PADDING_INPUT_SIDE
		font-size: $FONT_INPUT_GENERAL
		border-style: none
		+setBorderRadius($RADIUS_INPUT_GENERAL)
	input.InputToken
		min-width: $WIDTH_INPUT_SQUARE
		width: $WIDTH_INPUT_SQUARE
		text-align: center
	input::-webkit-outer-spin-button, input::-webkit-inner-spin-button
		+setAppearance(none)
		margin: 0px
	input[type=number]
		+setAppearance(textfield)
	mat-slider
		width: 100%
		height: 200px
	select
		min-height: $HEIGHT_SELECT_SINGLELINE
		background-repeat: no-repeat
		background-position: right
		+setAppearance(none)
	textarea
		height: $HEIGHT_TEXTAREA_GENERAL
	textarea.TextareaDescriptionHeader
		height: $HEIGHT_TEXTAREA_HEADER !important
		font-size: $FONT_INPUT_TEXTAREA_HEADERHEADLINE
		margin: 0px
	textarea:disabled
		height: $HEIGHT_TEXTAREA_READONLY
		resize: none
		padding: 0
		+setBorderRadius(0px)
	div.DivRadioButtonContainerVertical
		flex-direction: column
	div.DivRadioButtonContainer, div.DivRadioButtonContainerVertical
		display: flex
		div.DivRadioButton
			margin-right: $SPACE_LITTLE
			width: $SIZE_INPUT_RADIO
			height: $SIZE_INPUT_RADIO
			padding: $SPACE_TINY
			border-style: solid
			border-width: $WIDTH_BORDER_INPUTTHICK
			+setBorderRadius(50%)
			display: flex
			align-items: center
			align-content: center
			justify-content: center
			input[type=radio]
				display: none
			label
				margin: 0px
				width: 0px
				height: 0px
				+setBorderRadius(50%)
			input[type=radio]:checked + label
				width: 100%
				height: 100%
		div.DivRadioButton + label
			margin-right: $SPACE_MEDIUM
		div.DivRadioButtonMerge
			display: flex
			align-items: center
	select.SelectMobilePhonePrefix
		min-width: $WIDTH_INPUT_LICENSEPLATE_PREFIX
		width: $WIDTH_INPUT_MOBILEPHONE_PREFIX
		text-align: left
		margin-right: $SPACE_LITTLE
	select.InputLicensePlatePrefix
		min-width: $WIDTH_INPUT_LICENSEPLATE_PREFIX
		width: $WIDTH_INPUT_LICENSEPLATE_PREFIX
		text-align: left
		margin-right: $SPACE_TINY
	input.InputLicensePlateInfix
		min-width: $WIDTH_INPUT_LICENSEPLATE_INFIX
		// width: $WIDTH_INPUT_LICENSEPLATE_INFIX
		max-width: 500px
		text-align: center
		margin-right: $SPACE_TINY
		margin-left: $SPACE_TINY
	input.InputLicensePlateSuffix
		min-width: $WIDTH_INPUT_SQUARE
		width: $WIDTH_INPUT_LICENSEPLATE_SUFFIX
		max-width: $WIDTH_INPUT_MOBILEPHONE_PREFIX * 1.5
		text-align: center
		margin-left: $SPACE_TINY
	div.DivCheckBoxContainer
		display: flex
		align-items: center
		div.DivCheckBox
			margin-right: $SPACE_LITTLE
			width: $SIZE_INPUT_CHECKBOX
			height: $SIZE_INPUT_CHECKBOX
			padding: $PADDING_INPUT_CHECKBOX
			border-style: solid
			border-width: $WIDTH_BORDER_INPUTTHICK
			+setBorderRadius($RADIUS_INPUT_CHECKBOXOUTER)
			display: flex
			align-items: center
			align-content: center
			justify-content: center
			input[type=checkbox]
				display: none
			label
				margin: 0px
				width: 0px
				height: 0px
				+setBorderRadius($RADIUS_INPUT_CHECKBOXINNER)
			input[type=checkbox]:checked + label
				width: 100%
				height: 100%
		div.DivCheckBox + label
			margin-right: $SPACE_MEDIUM
			margin-top: $SPACE_NARROW
			margin-bottom: $SPACE_NARROW

div.DivMainAsideSearch, div.DivTransparantContainer
	div.DivDynamicContainer
		form
			width: auto
		input[type=button].ButtonSubmit
			width: $WIDTH_LINK_BUTTON_CONVERSATION
div.DivMainAsideSearch
	form
		margin-right: $SPACE_SMALL
		width: $WIDTH_LAYOUT_SIGNIN
		flex-shrink: 0

div.DivPremiumSimulation
	input, textarea
		margin: 10px auto

/* FORM - END */

/* THIRD PARTY - START */

div.DivForm, form div.DivForm fieldset
	fieldset
		mat-form-field
			width: 100%
	mat-form-field
		margin: 0px
		padding: 0px
		min-width: auto
	.mat-form-field-appearance-fill, .mat-form-field-wrapper, .mat-form-field-flex
		+setBorderRadius($RADIUS_INPUT_GENERAL)
	.mat-form-field-underline
		display: none
	.mat-form-field-wrapper
		padding-bottom: 0px
	input.mat-date-range-input-inner, input.mat-datepicker-input
		height: auto
		font: inherit
		border: none
		outline: none
		padding: 0
		margin: 0
		vertical-align: bottom
		text-align: inherit
		+setAppearance(none)
		width: 100%
	.mat-form-field
		margin-bottom: $SPACE_NARROW
		margin-top: $SPACE_NARROW

div.DivForm
	fieldset
		width: 100%
		padding: $SPACE_LITTLE
		margin-bottom: $SPACE_LITTLE
		margin-top: $SPACE_LITTLE
		border: none
		border-radius: $SPACE_LITTLE
	.ng-select
		margin-right: $SPACE_LITTLE
		width: 100%
	.ng-dropdown-panel
		width: 100%
		padding-left: $SPACE_LITTLE
		margin-left: -10px
	.ng-option
		margin-top: $SPACE_TINY
	.ng-select.custom .ng-clear-wrapper .ng-clear
		font-size: 0px

agm-map
	margin: 200px
	background-color: red
	height: 500px
	width: 100%

/* THIRD PARTY - END */

// GENERAL - END


// NAVIGATION - START

// NAVIGATION - END


// SIGN IN - START

// SIGN IN - END


// TABLE - START

div.DivTableContainer
	div.DivTableControl, div.DivConversationStatus
		select
			padding-top: $PADDING_INPUT_EDGE
			padding-bottom: $PADDING_INPUT_EDGE
			padding-right: $PADDING_INPUT_SIDE
			padding-left: $PADDING_INPUT_SIDE
			border-style: none
			background-repeat: no-repeat
			background-position: right
			+setAppearance(none)
	div.DivTableControl
		select
			margin-bottom: $SPACE_NARROW
			margin-top: $SPACE_NARROW
			width: 100%
			min-width: $WIDTH_INPUT_SINGLELINE
			min-height: $HEIGHT_SELECT_SINGLELINE
			font-size: $FONT_INPUT_GENERAL
			+setBorderRadius($RADIUS_INPUT_GENERAL)
	div.DivConversationStatus
		select
			font-size: $FONT_SELECT_SEVERITY
			width: $WIDTH_LABEL_CONVERSATIONSTATUS
			+setBorderRadius($RADIUS_TICKET_STATUS)
	div.DivConversationContent
		textarea
			+setBoxSizing(border-box)
			width: 100%
			min-width: $WIDTH_INPUT_SQUARE
			padding: $PADDING_INPUT_SIDE
			font-size: $FONT_INPUT_GENERAL
			border-style: none
			+setBorderRadius($RADIUS_INPUT_GENERAL)

// TABLE - END


div.DivLicensePlateContainer
	display: flex
	flex-direction: row
	justify-content: space-between
	flex-wrap: nowrap
	gap: $SPACE_MEDIUM
	align-items: end
	div.DivLicensePlate
		width: $SPACE_MEDIUM
		flex-basis: $SPACE_MEDIUM
		text-align: left
		vertical-align: middle
		flex-grow: 1
		flex-shrink: 1
		input.InputLicensePlate
			width: 100%


div#divMainDashboard
	mat-form-field
		margin: 0px
		padding: 0px
		min-width: auto
		background-color: white
		font-family: $URL_FAMILYTERTIARY_SECONDARY
	.mat-form-field-appearance-fill, .mat-form-field-wrapper, .mat-form-field-flex
		+setBorderRadius($RADIUS_INPUT_GENERAL)
		background-color: white
	.mat-form-field-underline
		display: none
	.mat-form-field-wrapper
		padding-bottom: 0px
		.mat-form-field-flex
			padding: 0px
			.mat-form-field-infix
				padding: 0px
				padding-left: 5px
				margin-top: -20px
	.mat-datepicker-toggle
		position: relative
		top: -5px
	input.mat-date-range-input-inner, input.mat-datepicker-input
		height: auto
		font: inherit
		border: none
		outline: none
		padding: 0
		margin: 0
		vertical-align: bottom
		text-align: inherit
		+setAppearance(none)
		width: 100%
	.mat-form-field
		width: 100%

// PRODUCT INPUT DISABLED
.InputDisabled, .InputDisabled::placeholder, .TextareaDisabled, .TextareaDisabled::placeholder
	color: #849FBD !important