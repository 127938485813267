/* You can add global styles to this file, and also import other style files */

body
	margin: 0px
	padding: 0px
	width: 100%
	height: 100%
/* Importing Bootstrap SCSS file. */
@import '~bootstrap/scss/bootstrap'

.pulse-loader
	display: flex
	align-items: center
	> div
		background-color: currentColor
		margin-right: 5%
		width: 30%
		height: 100%
		border-radius: 100%
		animation: pulse 1s ease infinite

@keyframes pulse
	0%, 60%, 100%
		opacity: 1
		transform: scale(1)

	30%
		opacity: .1
		transform: scale(.01)

